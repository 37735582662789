@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

nav {
  background-color: $deep-slate-blue_6;
  display: flex;
  width: 100%;
  min-height: 10vh;
  justify-content: center;
  flex-direction: row;
  z-index: 50;
  @include responsive(tablet) {
    justify-content: center;
  }
}

nav ul {
  display: flex;
  gap: 2em;
  justify-content: end;
  align-items: center;
  width: 60vw;
  margin: 0px;
  padding: 0px;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    padding: 0px;
    margin-right: 0vw;
    gap: 2em;
    width: $tablet-width;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
    justify-content: center;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    gap: 1em;
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    gap: 1em;
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    gap: 1em;
    width: $tablet-landscape-width;
  }
}

nav li {
  display: flex;
  list-style: none;
}

nav a {
  display: flex;
  transition: color $responsiveTime;
}

header {
  min-height: 94vh;
  background-color: $antique-white;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  @include responsive(tablet) {
    justify-content: flex-start;
  }
}

.header-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  gap: 2em;
  min-height: 84vh;
  width: 60vw;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    // min-height: 80%;
    width: $tablet-width;
  }
  @include responsive(mobile-large) {
    display: flex;
    flex-flow: column nowrap;
    justify-items: start;
    // min-height: 84%;
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    justify-content: space-between;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 0.6em;
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    justify-content: space-between;
    flex-flow: column nowrap;
    justify-content: center;
    gap: 0.6em;
    width: $tablet-landscape-width;
  }
}

#section-work {
  background-color: $deep-slate-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.grid {
  display: grid;
  width: 60vw;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 2rem;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
    gap: 1.5rem;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
    gap: 1.5rem;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

.grid-card {
  display: flex;
  flex-direction: column;
  display: block;
}

.grid img {
  object-fit: cover;
  height: 18vw;
  max-width: 100%;
  min-width: 100%;
  border-radius: 2px;
  transition: filter $smoothTime;
  filter: grayscale(100%) opacity(90%) contrast(80%);
  -webkit-filter: grayscale(100%) opacity(90%) contrast(80%);
  -moz-filter: grayscale(100%) opacity(90%) contrast(80%);
  box-shadow: $boxShadow;
  @include responsive(laptop-large) {
    height: 19vw;
  }
  @include responsive(laptop) {
    height: 20vw;
  }
  @include responsive(tablet) {
    height: 48vw;
  }
  @include responsive(mobile-large) {
    height: 50vw;
  }
  @include responsive(mobile-medium) {
    height: 52vw;
  }
  @include responsive(mobile-small) {
    height: 54vw;
  }
}

.grid > a:hover > h4 {
  border-bottom: solid 1px $terracotta;
}

.grid > a:hover > img {
  filter: grayscale(0%) opacity(100%) contrast(100%);
  -webkit-filter: grayscale(0%) opacity(100%) contrast(100%);
  -moz-filter: grayscale(0%) opacity(100%) contrast(100%);
}

#section-about {
  display: flex;
  flex-direction: column;
  align-content: center;
  position: relative;
  background-color: $antique-white;
}

#portrait {
  max-height: 300px;
  margin: 20px 0px 20px 30px;
  filter: sepia(0.4) blur(0.4px) invert(0.1) opacity(0.95);
  @include responsive(laptop) {
    max-height: 200px;
  }
  @include responsive(tablet) {
    display: none;
  }
}

#section-about-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 60vw;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
    padding: 5% 0% 5% 0%;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
    padding: 5% 0% 5% 0%;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}
