@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

#section-contact {
  background-color: $deep-slate-blue;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  @include responsive(tablet) {
    // padding-top: 10vh;
  }
  @include responsive(tablet-landscape) {
    // padding-top: 10vh;
  }
}

.section-contact-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  width: 60vw;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

#emailaddress,
#phonenumber {
  width: auto;
}

.social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-inline-start: 0px;
  margin: 0vh 0vh 5vh 0vh;
}

.social li {
  display: flex;
  margin: 5px 0px 5px 0px;
  @include responsive(tablet) {
    margin: 0px 0px 10px 0px;
  }
}

.social img {
  width: 1.5em;
  min-width: 32px;
}

.social p {
  display: block;
  height: 40px;
  padding: 0vh 0vh 0vh 1.2vh;
  margin: 0vh 0vh 0.6vh 0vh;
}

.contactstyle {
  background-color: $antique-white;
  border: none;
  padding: 10px 0px 10px 10px;
  margin: 10px 0px 25px 0px;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  border-radius: 2px;
  box-shadow: $boxShadow;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:autofill,
input:autofill:hover,
input:autofill:focus,
textarea:autofill,
textarea:autofill:hover,
textarea:autofill:focus,
select:autofill,
select:autofill:hover,
select:autofill:focus {
  border: none;
  -webkit-text-fill-color: $terracotta;
  transition: background-color $smoothTime;
}

.contactform {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  flex-basis: 100%;
}

.contactform > form > * {
  margin-bottom: 50px;
}

.contactform > form > .message-container {
  flex-basis: 100%;
  resize: none;
  margin-bottom: 30px;
}

.contactform > form label {
  display: block;
  opacity: 0.5;
}

.fullname-container,
.email-container {
  flex-basis: 48%;
  @include responsive(tablet) {
    flex-basis: 100%;
  }
}

.message-container {
  flex-basis: 100%;
}

.formsubmit-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-basis: 100%;
  @include responsive(tablet) {
    justify-content: center;
  }
}

footer {
  background-color: $deep-slate-blue;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-container {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  align-items: flex-end;
  border-top: solid 1px $muted-sage-green;
  padding: 14px 0px;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

.footer-container a {
  display: flex;
  font-size: 3rem;
  color: $muted-sage-green;
  font-family: Kings, 'Times New Roman', Times, serif;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.02em;
  @include responsive(tablet) {
    font-size: 2.5rem;
  }
  @include responsive(mobile-large) {
    font-size: 2rem;
  }
}

small.copyrightinfo {
  display: block;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  width: auto;
  @include responsive(tablet) {
    // margin: 20px 00px 10px 0px;
    padding: 0px 00px 00px 00px;
  }
}
