@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

.button-cta {
  width: fit-content;
  padding: 16px 22px;
  border-radius: 24px;
  font-size: 1.3rem;
  font-weight: 500;
  border: none;
  margin: 0;
  background: $terracotta;
  cursor: pointer;
  transition: background $responsiveTime;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  text-align: center;
  color: $antique-white;
  @include responsive(laptop-large) {
    font-size: 1.1rem;
    padding: 16px 22px;
    border-radius: 22px;
  }
  @include responsive(laptop) {
    font-size: 1.1rem;
    padding: 14px 20px;
    border-radius: 20px;
  }
  @include responsive(tablet) {
    font-size: 1.1rem;
    padding: 13px 18px;
    border-radius: 18px;
  }
  @include responsive(mobile-large) {
    font-size: 1.1rem;
    padding: 12px 17px;
    border-radius: 18px;
  }
  @include responsive(mobile-small) {
    font-size: 1rem;
    padding: 12px 16px;
    border-radius: 16px;
  }
  @include responsive(tablet-landscape) {
    font-size: 1.1rem;
    padding: 1.5vw 1.5vw;
  }
}

.button-cta:hover,
.button-cta:active {
  background: $muted-sage-green;
}

.button-cta:focus {
  outline: none;
}

#contactsubmit {
  width: fit-content;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 14px 26px;
  border-radius: 22px;
  margin: 0;
  border: none;
  color: $antique-white;
  cursor: pointer;
  outline: none;
  background-color: $terracotta;
  transition: background $responsiveTime;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  @include responsive(laptop-large) {
    font-size: 1.3rem;
    padding: 13px 25px;
    border-radius: 21px;
  }
  @include responsive(laptop) {
    font-size: 1.3rem;
    padding: 12px 24px;
    border-radius: 20px;
  }
  @include responsive(tablet) {
    font-size: 1.3rem;
    padding: 11px 23px;
    border-radius: 18px;
  }
  @include responsive(mobile-large) {
    font-size: 1.3rem;
    padding: 11px 23px;
    border-radius: 18px;
  }
  @include responsive(mobile-medium) {
    font-size: 1.2rem;
    padding: 10px 22px;
    border-radius: 17px;
  }
  @include responsive(mobile-medium) {
    font-size: 1.2rem;
    padding: 9px 18px;
    border-radius: 16px;
  }
  @include responsive(tablet-landscape) {
    font-size: 1.3rem;
    padding: 2vw 5vw;
    border-radius: 16px;
  }
}

#contactsubmit:hover,
#contactsubmit:active {
  background-color: $muted-sage-green;
}

#contactsubmit:focus {
  outline: none;
}

#myBtn {
  width: fit-content;
  border-radius: 19px;
  font-size: 1rem;
  padding: 15px;
  font-weight: 500;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $terracotta;
  cursor: pointer;
  transition: background $responsiveTime;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  color: $antique-white;
  @include responsive(laptop-large) {
    padding: 14px;
    border-radius: 20px;
  }
  @include responsive(laptop) {
    padding: 14px;
    border-radius: 19px;
  }
  @include responsive(tablet) {
    padding: 14px;
    border-radius: 18px;
  }
  @include responsive(mobile-large) {
    padding: 13px;
    border-radius: 17px;
  }
}

#myBtn:hover,
#myBtn:active {
  background-color: $muted-sage-green;
}

#myBtn:focus {
  outline: none;
}

.button-back {
  width: fit-content;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 14px 26px;
  border-radius: 24px;
  text-decoration: none;
  text-align: center;
  color: $antique-white;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border: none;
  background: $terracotta;
  cursor: pointer;
  margin: 0;
  transition: background $responsiveTime;
  @include responsive(laptop-large) {
    font-size: 1.3rem;
    padding: 13px 25px;
    border-radius: 24px;
  }
  @include responsive(laptop) {
    font-size: 1.3rem;
    padding: 12px 24px;
    border-radius: 23px;
  }
  @include responsive(tablet) {
    font-size: 1.3rem;
    padding: 12px 24px;
    border-radius: 21px;
  }
  @include responsive(mobile-large) {
    font-size: 1.2rem;
    padding: 11px 23px;
    border-radius: 19px;
  }
  @include responsive(mobile-medium) {
    font-size: 1.2rem;
    padding: 10px 22px;
    border-radius: 18px;
  }
  @include responsive(mobile-small) {
    font-size: 1.2rem;
    padding: 10px 22px;
    border-radius: 18px;
  }
  @include responsive(tablet-landscape) {
    font-size: 1.3rem;
    padding: 2vw 5vw;
    border-radius: 16px;
  }
}

.button-back:hover,
.button-back:active {
  background: $muted-sage-green;
}

.button-back:focus {
  outline: none;
}
