@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

body {
  height: 100vh;
  width: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  background-color: $antique-white;
}

section {
  scroll-margin-top: -1px;
  align-items: center;
  padding: 10vh 0px 10vh 0px;
  @include responsive(laptop) {
    padding: 8vh 0px 8vh 0px;
  }
  @include responsive(tablet) {
    padding: 6vh 0px 6vh 0px;
  }
  @include responsive(mobile-large) {
    padding: 5vh 0px 5vh 0px;
  }
  @include responsive(mobile-medium) {
    padding: 4.5vh 0px 4.5vh 0px;
  }
}

a {
  transition: color $responsiveTime;
}
