@mixin responsive($breakpoint) {
  @if $breakpoint == mobile-small {
    @media only screen and (max-width: 320px) {
      @content;
    }
  }
  @if $breakpoint == mobile-medium {
    @media only screen and (max-width: 375px) {
      @content;
    }
  }
  @if $breakpoint == mobile-large {
    @media only screen and (max-width: 425px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == laptop {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == laptop-large {
    @media only screen and (max-width: 1440px) {
      @content;
    }
  }
  @if $breakpoint == 4k {
    @media only screen and (max-width: 2560px) {
      @content;
    }
  }
  @if $breakpoint == tablet-landscape {
    @media only screen and (max-width: 768px) and (orientation: landscape) {
      @content;
    }
  }
  @if $breakpoint == laptop-landscape {
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      @content;
    }
  }
}
