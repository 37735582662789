*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
}

@font-face {
  font-family: Kings;
  src: url("kingsgmb.97e4e0e9.ttf");
}

@font-face {
  font-family: Montserrat-Variable;
  src: url("Montserrat-VariableFont_wght.c16ad322.ttf");
}

html {
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  font-size: 125%;
  font-weight: 400;
}

@media only screen and (width <= 1024px) {
  html {
    font-size: 115%;
  }
}

@media only screen and (width <= 768px) {
  html {
    font-size: 100%;
  }
}

nav a {
  color: #3d405c;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
}

nav a:hover {
  color: #e17c60;
}

h1 {
  color: #3d405c;
  font-family: Kings, Times New Roman, Times, serif;
  font-size: 12rem;
  line-height: .8;
}

@media only screen and (width <= 1440px) {
  h1 {
    font-size: 9rem;
  }
}

@media only screen and (width <= 1024px) {
  h1 {
    font-size: 8rem;
  }
}

@media only screen and (width <= 768px) {
  h1 {
    font-size: 6rem;
  }
}

@media only screen and (width <= 425px) {
  h1 {
    font-size: 5.5rem;
  }
}

@media only screen and (width <= 375px) {
  h1 {
    font-size: 5rem;
  }
}

@media only screen and (width <= 320px) {
  h1 {
    font-size: 4rem;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  h1 {
    font-size: 5rem;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  h1 {
    font-size: 4.8rem;
  }
}

.h1-example {
  font-size: 8rem;
  line-height: 1;
}

@media only screen and (width <= 1440px) {
  .h1-example {
    font-size: 6.4rem;
  }
}

@media only screen and (width <= 1024px) {
  .h1-example {
    font-size: 6rem;
  }
}

@media only screen and (width <= 768px) {
  .h1-example {
    font-size: 4rem;
  }
}

@media only screen and (width <= 425px) {
  .h1-example {
    font-size: 3.5rem;
  }
}

@media only screen and (width <= 375px) {
  .h1-example {
    font-size: 3.2rem;
  }
}

@media only screen and (width <= 320px) {
  .h1-example {
    font-size: 2.5rem;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .h1-example {
    font-size: 5rem;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .h1-example {
    font-size: 4rem;
  }
}

.header-container p {
  color: #3d405c;
  margin-bottom: 0;
  font-size: 1.1rem;
  font-weight: 450;
  line-height: 1.4;
}

h2 {
  letter-spacing: .01em;
  color: #81b19a;
  border-bottom: 1px solid #81b19a;
  width: 60vw;
  margin-bottom: 9vh;
  font-family: Kings, Times New Roman, Times, serif;
  font-size: 4.5rem;
}

@media only screen and (width <= 1440px) {
  h2 {
    width: 68vw;
    font-size: 4rem;
  }
}

@media only screen and (width <= 1024px) {
  h2 {
    width: 76vw;
    margin-bottom: 6vh;
    font-size: 3.5rem;
  }
}

@media only screen and (width <= 768px) {
  h2 {
    width: 82vw;
    margin-bottom: 5vh;
    font-size: 3rem;
  }
}

@media only screen and (width <= 425px) {
  h2 {
    width: 83vw;
    font-size: 2.5rem;
  }
}

@media only screen and (width <= 375px) {
  h2 {
    width: 85vw;
    margin-bottom: 4.5vh;
    font-size: 2.3rem;
  }
}

@media only screen and (width <= 320px) {
  h2 {
    width: 90vw;
    font-size: 2rem;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  h2 {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  h2 {
    width: 68vw;
  }
}

.h2-example {
  color: #3d405c;
  letter-spacing: 0;
  border-bottom: 1px solid #3d405c;
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
}

.section-heading-light {
  color: #3d405c;
  border-bottom: 1px solid #3d405c;
}

h3 {
  color: #f4f0dd;
  margin: 0;
  padding-top: .7vw;
  font-weight: 300;
  transition: color .4s;
}

@media only screen and (width <= 1440px) {
  h3 {
    padding-top: .8vw;
    font-size: 1rem;
  }
}

@media only screen and (width <= 1024px) {
  h3 {
    padding-top: 1vw;
  }
}

@media only screen and (width <= 768px) {
  h3 {
    padding-top: 1.5vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  h3 {
    padding-top: 1.5vw;
  }
}

.grid > a:hover > h3 {
  color: #e17c60;
}

.h3-example {
  color: #3d405c;
  font-weight: 600;
  line-height: 2;
}

h4 {
  letter-spacing: .04em;
  color: #81b19a;
  border-bottom: 1px solid #81b19a;
  margin: .1rem 0 0;
  padding-bottom: 1vw;
  font-size: .8rem;
  font-weight: 400;
  transition: color .4s, border .4s;
  display: block;
}

@media only screen and (width <= 1440px) {
  h4 {
    margin: 0;
    padding-bottom: .9vw;
    font-size: .7rem;
  }
}

@media only screen and (width <= 1024px) {
  h4 {
    margin: 0;
    padding-bottom: .8vw;
    font-size: .6rem;
  }
}

.grid > a:hover > h4 {
  color: #e17c60;
}

.grid a {
  text-decoration: none;
}

p {
  color: #3d405c;
  max-width: 55ch;
  margin-bottom: 30px;
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.copyrightinfo {
  color: #81b19a;
  font-size: 3rem;
}

@media only screen and (width <= 768px) {
  .copyrightinfo {
    font-size: 2.5rem;
  }
}

@media only screen and (width <= 425px) {
  .copyrightinfo {
    font-size: 2rem;
  }
}

.social a {
  color: #f4f0dd;
  text-decoration: none;
}

.social a:hover {
  color: #e17c60;
}

.social p {
  color: #f4f0dd;
  line-height: 43px;
}

@media only screen and (width <= 320px) {
  .social p {
    padding: 0;
    font-size: .9rem;
  }
}

label {
  color: #f4f0dd;
}

label.focused {
  color: #e17c60;
}

textarea {
  line-height: 1.5;
}

footer {
  color: #f4f0dd;
}

.button-cta {
  cursor: pointer;
  text-align: center;
  color: #f4f0dd;
  background: #e17c60;
  border: none;
  border-radius: 24px;
  width: fit-content;
  margin: 0;
  padding: 16px 22px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  transition: background .1s;
  box-shadow: 0 3px 15px #0003;
}

@media only screen and (width <= 1440px) {
  .button-cta {
    border-radius: 22px;
    padding: 16px 22px;
    font-size: 1.1rem;
  }
}

@media only screen and (width <= 1024px) {
  .button-cta {
    border-radius: 20px;
    padding: 14px 20px;
    font-size: 1.1rem;
  }
}

@media only screen and (width <= 768px) {
  .button-cta {
    border-radius: 18px;
    padding: 13px 18px;
    font-size: 1.1rem;
  }
}

@media only screen and (width <= 425px) {
  .button-cta {
    border-radius: 18px;
    padding: 12px 17px;
    font-size: 1.1rem;
  }
}

@media only screen and (width <= 320px) {
  .button-cta {
    border-radius: 16px;
    padding: 12px 16px;
    font-size: 1rem;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .button-cta {
    padding: 1.5vw;
    font-size: 1.1rem;
  }
}

.button-cta:hover, .button-cta:active {
  background: #81b19a;
}

.button-cta:focus {
  outline: none;
}

#contactsubmit {
  color: #f4f0dd;
  cursor: pointer;
  background-color: #e17c60;
  border: none;
  border-radius: 22px;
  outline: none;
  width: fit-content;
  margin: 0;
  padding: 14px 26px;
  font-size: 1.3rem;
  font-weight: 500;
  transition: background .1s;
  box-shadow: 0 3px 15px #0003;
}

@media only screen and (width <= 1440px) {
  #contactsubmit {
    border-radius: 21px;
    padding: 13px 25px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 1024px) {
  #contactsubmit {
    border-radius: 20px;
    padding: 12px 24px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 768px) {
  #contactsubmit {
    border-radius: 18px;
    padding: 11px 23px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 425px) {
  #contactsubmit {
    border-radius: 18px;
    padding: 11px 23px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 375px) {
  #contactsubmit {
    border-radius: 16px;
    padding: 9px 18px;
    font-size: 1.2rem;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  #contactsubmit {
    border-radius: 16px;
    padding: 2vw 5vw;
    font-size: 1.3rem;
  }
}

#contactsubmit:hover, #contactsubmit:active {
  background-color: #81b19a;
}

#contactsubmit:focus {
  outline: none;
}

#myBtn {
  z-index: 99;
  cursor: pointer;
  color: #f4f0dd;
  background-color: #e17c60;
  border: none;
  border-radius: 19px;
  outline: none;
  width: fit-content;
  padding: 15px;
  font-size: 1rem;
  font-weight: 500;
  transition: background .1s;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  box-shadow: 0 3px 15px #0003;
}

@media only screen and (width <= 1440px) {
  #myBtn {
    border-radius: 20px;
    padding: 14px;
  }
}

@media only screen and (width <= 1024px) {
  #myBtn {
    border-radius: 19px;
    padding: 14px;
  }
}

@media only screen and (width <= 768px) {
  #myBtn {
    border-radius: 18px;
    padding: 14px;
  }
}

@media only screen and (width <= 425px) {
  #myBtn {
    border-radius: 17px;
    padding: 13px;
  }
}

#myBtn:hover, #myBtn:active {
  background-color: #81b19a;
}

#myBtn:focus {
  outline: none;
}

.button-back {
  text-align: center;
  color: #f4f0dd;
  cursor: pointer;
  background: #e17c60;
  border: none;
  border-radius: 24px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: fit-content;
  margin: 0;
  padding: 14px 26px;
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: none;
  transition: background .1s;
  display: flex;
  box-shadow: 0 3px 15px #0003;
}

@media only screen and (width <= 1440px) {
  .button-back {
    border-radius: 24px;
    padding: 13px 25px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 1024px) {
  .button-back {
    border-radius: 23px;
    padding: 12px 24px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 768px) {
  .button-back {
    border-radius: 21px;
    padding: 12px 24px;
    font-size: 1.3rem;
  }
}

@media only screen and (width <= 425px) {
  .button-back {
    border-radius: 19px;
    padding: 11px 23px;
    font-size: 1.2rem;
  }
}

@media only screen and (width <= 375px) {
  .button-back {
    border-radius: 18px;
    padding: 10px 22px;
    font-size: 1.2rem;
  }
}

@media only screen and (width <= 320px) {
  .button-back {
    border-radius: 18px;
    padding: 10px 22px;
    font-size: 1.2rem;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .button-back {
    border-radius: 16px;
    padding: 2vw 5vw;
    font-size: 1.3rem;
  }
}

.button-back:hover, .button-back:active {
  background: #81b19a;
}

.button-back:focus {
  outline: none;
}

#section-contact {
  background-color: #3d405c;
  place-content: center;
  display: flex;
  position: relative;
}

.section-contact-container {
  flex-flow: column;
  place-content: center;
  width: 60vw;
  display: flex;
}

@media only screen and (width <= 1440px) {
  .section-contact-container {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .section-contact-container {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .section-contact-container {
    width: 82vw;
  }
}

@media only screen and (width <= 425px) {
  .section-contact-container {
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  .section-contact-container {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .section-contact-container {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .section-contact-container {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .section-contact-container {
    width: 68vw;
  }
}

#emailaddress, #phonenumber {
  width: auto;
}

.social {
  flex-direction: column;
  justify-content: center;
  margin: 0 0 5vh;
  padding-inline-start: 0;
  display: flex;
}

.social li {
  margin: 5px 0;
  display: flex;
}

@media only screen and (width <= 768px) {
  .social li {
    margin: 0 0 10px;
  }
}

.social img {
  width: 1.5em;
  min-width: 32px;
}

.social p {
  height: 40px;
  margin: 0 0 .6vh;
  padding: 0 0 0 1.2vh;
  display: block;
}

.contactstyle {
  box-sizing: border-box;
  resize: none;
  background-color: #f4f0dd;
  border: none;
  border-radius: 2px;
  outline: none;
  width: 100%;
  margin: 10px 0 25px;
  padding: 10px 0 10px 10px;
  box-shadow: 0 3px 15px #0003;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

input:autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

input:-webkit-autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

input:autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

input:autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:-webkit-autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:-webkit-autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

textarea:autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:-webkit-autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:autofill {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:-webkit-autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:autofill:hover {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:-webkit-autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

select:autofill:focus {
  -webkit-text-fill-color: #e17c60;
  border: none;
  transition: background-color .4s;
}

.contactform {
  text-align: left;
  flex-wrap: wrap;
  flex-basis: 100%;
  justify-content: space-between;
  display: flex;
}

.contactform > form > * {
  margin-bottom: 50px;
}

.contactform > form > .message-container {
  resize: none;
  flex-basis: 100%;
  margin-bottom: 30px;
}

.contactform > form label {
  opacity: .5;
  display: block;
}

.fullname-container, .email-container {
  flex-basis: 48%;
}

@media only screen and (width <= 768px) {
  .fullname-container, .email-container {
    flex-basis: 100%;
  }
}

.message-container {
  flex-basis: 100%;
}

.formsubmit-container {
  flex-flow: wrap;
  flex-basis: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (width <= 768px) {
  .formsubmit-container {
    justify-content: center;
  }
}

footer {
  background-color: #3d405c;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.footer-container {
  border-top: 1px solid #81b19a;
  justify-content: space-between;
  align-items: flex-end;
  width: 60vw;
  padding: 14px 0;
  display: flex;
}

@media only screen and (width <= 1440px) {
  .footer-container {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .footer-container {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    width: 82vw;
    display: flex;
  }
}

@media only screen and (width <= 425px) {
  .footer-container {
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  .footer-container {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .footer-container {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .footer-container {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .footer-container {
    width: 68vw;
  }
}

.footer-container a {
  color: #81b19a;
  letter-spacing: .02em;
  font-family: Kings, Times New Roman, Times, serif;
  font-size: 3rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

@media only screen and (width <= 768px) {
  .footer-container a {
    font-size: 2.5rem;
  }
}

@media only screen and (width <= 425px) {
  .footer-container a {
    font-size: 2rem;
  }
}

small.copyrightinfo {
  width: auto;
  margin: 0;
  padding: 0;
  display: block;
}

@media only screen and (width <= 768px) {
  small.copyrightinfo {
    padding: 0;
  }
}

.header-example {
  min-height: auto;
}

.main-example {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section-example {
  flex-direction: column;
  align-items: center;
  width: 60vw;
  height: auto;
  padding-bottom: 0;
  display: flex;
}

@media only screen and (width <= 1440px) {
  .section-example {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .section-example {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .section-example {
    width: 82vw;
  }
}

@media only screen and (width <= 425px) {
  .section-example {
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  .section-example {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .section-example {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .section-example {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .section-example {
    width: 68vw;
  }
}

.section-example .example-information > a {
  color: #3d405c;
  width: 60vw;
  padding-top: 10px;
  font-weight: bold;
  transition: color .1s;
  display: block;
}

.tech-used {
  color: #f4f0dd;
  background-color: #81b19a;
  border-radius: 2px;
  width: fit-content;
  padding: 8px;
  box-shadow: 0 3px 15px #0003;
}

.container {
  flex-direction: column;
  justify-content: left;
  width: 60vw;
  display: flex;
}

@media only screen and (width <= 1440px) {
  .container {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .container {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .container {
    width: 82vw;
  }
}

@media only screen and (width <= 425px) {
  .container {
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  .container {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .container {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .container {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .container {
    width: 68vw;
  }
}

iframe {
  border: none;
  border-radius: 2px;
  margin: 50px 0;
  box-shadow: 0 3px 15px #0003;
}

.section-example .container > img {
  border-radius: 2px;
  margin: 50px 0;
  box-shadow: 0 3px 15px #0003;
}

.section-example .container img[src*="svg"] {
  box-shadow: none;
}

.example-logo {
  object-fit: cover;
  width: 25vw;
}

@media only screen and (width <= 1440px) {
  .example-logo {
    width: 32vw;
  }
}

@media only screen and (width <= 1024px) {
  .example-logo {
    width: 40vw;
  }
}

@media only screen and (width <= 768px) {
  .example-logo {
    width: 54vw;
  }
}

@media only screen and (width <= 425px) {
  .example-logo {
    width: 60vw;
  }
}

@media only screen and (width <= 375px) {
  .example-logo {
    width: 62vw;
  }
}

@media only screen and (width <= 320px) {
  .example-logo {
    width: 65vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .example-logo {
    width: 45vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .example-logo {
    width: 50vw;
  }
}

.main-list {
  line-height: 1.5;
}

.tech-list {
  flex-flow: wrap;
  gap: 6px;
  padding-inline-start: 0;
  list-style-type: none;
  display: flex;
}

.nav-back {
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  display: flex;
}

body {
  background-color: #f4f0dd;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  margin: 0;
  display: flex;
}

section {
  align-items: center;
  padding: 10vh 0;
  scroll-margin-top: -1px;
}

@media only screen and (width <= 1024px) {
  section {
    padding: 8vh 0;
  }
}

@media only screen and (width <= 768px) {
  section {
    padding: 6vh 0;
  }
}

@media only screen and (width <= 425px) {
  section {
    padding: 5vh 0;
  }
}

@media only screen and (width <= 375px) {
  section {
    padding: 4.5vh 0;
  }
}

a {
  transition: color .1s;
}

nav {
  z-index: 50;
  background-color: #3d405c0f;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 10vh;
  display: flex;
}

@media only screen and (width <= 768px) {
  nav {
    justify-content: center;
  }
}

nav ul {
  justify-content: end;
  align-items: center;
  gap: 2em;
  width: 60vw;
  margin: 0;
  padding: 0;
  display: flex;
}

@media only screen and (width <= 1440px) {
  nav ul {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  nav ul {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  nav ul {
    gap: 2em;
    width: 82vw;
    margin-right: 0;
    padding: 0;
  }
}

@media only screen and (width <= 425px) {
  nav ul {
    justify-content: center;
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  nav ul {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  nav ul {
    gap: 1em;
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  nav ul {
    gap: 1em;
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  nav ul {
    gap: 1em;
    width: 68vw;
  }
}

nav li {
  list-style: none;
  display: flex;
}

nav a {
  transition: color .1s;
  display: flex;
}

header {
  background-color: #f4f0dd;
  background-position: center;
  background-size: cover;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  min-height: 94vh;
  display: flex;
}

@media only screen and (width <= 768px) {
  header {
    justify-content: flex-start;
  }
}

.header-container {
  flex-flow: column;
  justify-content: center;
  gap: 2em;
  width: 60vw;
  min-height: 84vh;
  display: flex;
}

@media only screen and (width <= 1440px) {
  .header-container {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .header-container {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .header-container {
    width: 82vw;
  }
}

@media only screen and (width <= 425px) {
  .header-container {
    flex-flow: column;
    justify-items: start;
    width: 83vw;
    display: flex;
  }
}

@media only screen and (width <= 375px) {
  .header-container {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .header-container {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .header-container {
    flex-flow: column;
    justify-content: center;
    gap: .6em;
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .header-container {
    flex-flow: column;
    justify-content: center;
    gap: .6em;
    width: 68vw;
  }
}

#section-work {
  background-color: #3d405c;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.grid {
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 2rem;
  width: 60vw;
  display: grid;
}

@media only screen and (width <= 1440px) {
  .grid {
    gap: 1.5rem;
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  .grid {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  .grid {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 82vw;
  }
}

@media only screen and (width <= 425px) {
  .grid {
    gap: 1.5rem;
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  .grid {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  .grid {
    width: 90vw;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  .grid {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  .grid {
    width: 68vw;
  }
}

.grid-card {
  flex-direction: column;
  display: block;
}

.grid img {
  object-fit: cover;
  -webkit-filter: grayscale() opacity(90%) contrast(80%);
  -moz-filter: grayscale(100%) opacity(90%) contrast(80%);
  border-radius: 2px;
  min-width: 100%;
  max-width: 100%;
  height: 18vw;
  transition: filter .4s;
  box-shadow: 0 3px 15px #0003;
}

@media only screen and (width <= 1440px) {
  .grid img {
    height: 19vw;
  }
}

@media only screen and (width <= 1024px) {
  .grid img {
    height: 20vw;
  }
}

@media only screen and (width <= 768px) {
  .grid img {
    height: 48vw;
  }
}

@media only screen and (width <= 425px) {
  .grid img {
    height: 50vw;
  }
}

@media only screen and (width <= 375px) {
  .grid img {
    height: 52vw;
  }
}

@media only screen and (width <= 320px) {
  .grid img {
    height: 54vw;
  }
}

.grid > a:hover > h4 {
  border-bottom: 1px solid #e17c60;
}

.grid > a:hover > img {
  -webkit-filter: grayscale(0%) opacity() contrast();
  -moz-filter: grayscale(0%) opacity(100%) contrast(100%);
}

#section-about {
  background-color: #f4f0dd;
  flex-direction: column;
  align-content: center;
  display: flex;
  position: relative;
}

#portrait {
  filter: sepia(.4) blur(.4px) invert(.1) opacity(.95);
  max-height: 300px;
  margin: 20px 0 20px 30px;
}

@media only screen and (width <= 1024px) {
  #portrait {
    max-height: 200px;
  }
}

@media only screen and (width <= 768px) {
  #portrait {
    display: none;
  }
}

#section-about-container {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 60vw;
  display: flex;
}

@media only screen and (width <= 1440px) {
  #section-about-container {
    width: 68vw;
  }
}

@media only screen and (width <= 1024px) {
  #section-about-container {
    width: 76vw;
  }
}

@media only screen and (width <= 768px) {
  #section-about-container {
    width: 82vw;
    padding: 5% 0%;
  }
}

@media only screen and (width <= 425px) {
  #section-about-container {
    width: 83vw;
  }
}

@media only screen and (width <= 375px) {
  #section-about-container {
    width: 85vw;
  }
}

@media only screen and (width <= 320px) {
  #section-about-container {
    width: 90vw;
    padding: 5% 0%;
  }
}

@media only screen and (width <= 1024px) and (orientation: landscape) {
  #section-about-container {
    width: 68vw;
  }
}

@media only screen and (width <= 768px) and (orientation: landscape) {
  #section-about-container {
    width: 68vw;
  }
}
/*# sourceMappingURL=index.15f0a2d0.css.map */
