@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

@font-face {
  font-family: 'Kings';
  src: url('../fonts/kingsgmb.ttf');
}

@font-face {
  font-family: 'Montserrat-Variable';
  src: url('../fonts/Montserrat-VariableFont_wght.ttf');
}

html {
  font-size: 125%;
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  font-weight: 400;
  @include responsive(laptop) {
    font-size: 115%;
  }
  @include responsive(tablet) {
    font-size: 100%;
  }
}

nav a {
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  color: $deep-slate-blue;
}

nav a:hover {
  color: $terracotta;
}

h1 {
  font-size: 12rem;
  line-height: 0.8;
  font-family: Kings, 'Times New Roman', Times, serif;
  color: $deep-slate-blue;
  @include responsive(laptop-large) {
    font-size: 9rem;
  }
  @include responsive(laptop) {
    font-size: 8rem;
  }
  @include responsive(tablet) {
    font-size: 6rem;
  }
  @include responsive(mobile-large) {
    font-size: 5.5rem;
  }
  @include responsive(mobile-medium) {
    font-size: 5rem;
  }
  @include responsive(mobile-small) {
    font-size: 4rem;
  }
  @include responsive(laptop-landscape) {
    font-size: 5rem;
  }
  @include responsive(tablet-landscape) {
    font-size: 4.8rem;
  }
}

.h1-example {
  font-size: 8rem;
  line-height: 1;
  @include responsive(laptop-large) {
    font-size: 6.4rem;
  }
  @include responsive(laptop) {
    font-size: 6rem;
  }
  @include responsive(tablet) {
    font-size: 4rem;
  }
  @include responsive(mobile-large) {
    font-size: 3.5rem;
  }
  @include responsive(mobile-medium) {
    font-size: 3.2rem;
  }
  @include responsive(mobile-small) {
    font-size: 2.5rem;
  }
  @include responsive(laptop-landscape) {
    font-size: 5rem;
  }
  @include responsive(tablet-landscape) {
    font-size: 4rem;
  }
}

.header-container p {
  font-size: 1.1rem;
  font-weight: 450;
  line-height: 1.4;
  color: $deep-slate-blue;
  margin-bottom: 0px;
}

h2 {
  letter-spacing: 0.01em;
  font-size: 4.5rem;
  width: 60vw;
  margin-bottom: 9vh;
  border-bottom: solid 1px $muted-sage-green;
  color: $muted-sage-green;
  font-family: Kings, 'Times New Roman', Times, serif;
  @include responsive(laptop-large) {
    font-size: 4rem;
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    font-size: 3.5rem;
    width: $laptop-width;
    margin-bottom: 6vh;
  }
  @include responsive(tablet) {
    font-size: 3rem;
    width: $tablet-width;
    margin-bottom: 5vh;
  }
  @include responsive(mobile-large) {
    font-size: 2.5rem;
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    font-size: 2.3rem;
    width: $mobile-medium-width;
    margin-bottom: 4.5vh;
  }
  @include responsive(mobile-small) {
    font-size: 2rem;
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

.h2-example {
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: $deep-slate-blue;
  line-height: 1.5;
  border-bottom: 1px solid $deep-slate-blue;
  letter-spacing: 0em;
}

.section-heading-light {
  color: $deep-slate-blue;
  border-bottom: solid 1px $deep-slate-blue;
}

h3 {
  color: $antique-white;
  font-weight: 300;
  padding-top: 0.7vw;
  margin: 0px 0px 0px 0px;
  transition: color $smoothTime;
  @include responsive(laptop-large) {
    font-size: 1rem;
    padding-top: 0.8vw;
  }
  @include responsive(laptop) {
    padding-top: 1vw;
  }
  @include responsive(tablet) {
    padding-top: 1.5vw;
  }
  @include responsive(laptop-landscape) {
    padding-top: 1.5vw;
  }
}

.grid > a:hover > h3 {
  color: $terracotta;
}

.h3-example {
  color: $deep-slate-blue;
  font-weight: 600;
  line-height: 2;
}

h4 {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  color: $muted-sage-green;
  margin: 0.1rem 0px 0px 0px;
  border-bottom: solid 1px $muted-sage-green;
  transition: color $smoothTime, border $smoothTime;
  padding-bottom: 1vw;
  display: block;
  @include responsive(laptop-large) {
    font-size: 0.7rem;
    padding-bottom: 0.9vw;
    margin: 0rem 0px 0px 0px;
  }
  @include responsive(laptop) {
    font-size: 0.6rem;
    padding-bottom: 0.8vw;
    margin: 0px;
  }
}

.grid > a:hover > h4 {
  color: $terracotta;
}

.grid a {
  text-decoration: none;
}

p {
  font-size: 1rem;
  margin-bottom: 30px;
  line-height: 1.5;
  font-family: Montserrat-Variable, Arial, Helvetica, sans-serif;
  max-width: 55ch;
  color: $deep-slate-blue;
}

.copyrightinfo {
  font-size: 3rem;
  color: $muted-sage-green;
  @include responsive(tablet) {
    font-size: 2.5rem;
  }
  @include responsive(mobile-large) {
    font-size: 2rem;
  }
}

.social a {
  color: $antique-white;
  text-decoration: none;
}

.social a:hover {
  color: $terracotta;
}

.social p {
  line-height: 43px;
  color: $antique-white;
  @include responsive(mobile-small) {
    padding: 0vh;
    font-size: 0.9rem;
  }
}

label {
  color: $antique-white;
}

label.focused {
  color: $terracotta;
}

textarea {
  line-height: 1.5;
}

footer {
  color: $antique-white;
}
