@use '../helpers/colour' as *;
@use '../helpers/mixins' as *;
@use '../helpers/variables' as *;

.header-example {
  min-height: auto;
}

.main-example {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-example {
  padding-bottom: 0px;
  align-items: center;
  display: flex;
  height: auto;
  flex-direction: column;
  width: 60vw;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

.section-example .example-information > a {
  color: $deep-slate-blue;
  display: block;
  transition: color $responsiveTime;
  padding-top: 10px;
  font-weight: bold;
  width: 60vw;
}

.tech-used {
  width: fit-content;
  border-radius: 2px;
  padding: 8px;
  background-color: $muted-sage-green;
  color: $antique-white;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.container {
  display: flex;
  flex-direction: column;
  width: 60vw;
  justify-content: left;
  @include responsive(laptop-large) {
    width: $laptop-large-width;
  }
  @include responsive(laptop) {
    width: $laptop-width;
  }
  @include responsive(tablet) {
    width: $tablet-width;
  }
  @include responsive(mobile-large) {
    width: $mobile-large-width;
  }
  @include responsive(mobile-medium) {
    width: $mobile-medium-width;
  }
  @include responsive(mobile-small) {
    width: $mobile-small-width;
  }
  @include responsive(laptop-landscape) {
    width: $laptop-landscape-width;
  }
  @include responsive(tablet-landscape) {
    width: $tablet-landscape-width;
  }
}

iframe {
  margin: 50px 0 50px;
  border-radius: 2px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  border: none;
}

.section-example .container > img {
  margin: 50px 0 50px;
  border-radius: 2px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.section-example .container img[src*='svg'] {
  box-shadow: none;
}

.example-logo {
  object-fit: cover;
  width: 25vw;
  @include responsive(laptop-large) {
    width: 32vw;
  }
  @include responsive(laptop) {
    width: 40vw;
  }
  @include responsive(tablet) {
    width: 54vw;
  }
  @include responsive(mobile-large) {
    width: 60vw;
  }
  @include responsive(mobile-medium) {
    width: 62vw;
  }
  @include responsive(mobile-small) {
    width: 65vw;
  }
  @include responsive(laptop-landscape) {
    width: 45vw;
  }
  @include responsive(tablet-landscape) {
    width: 50vw;
  }
}

.main-list {
  // margin: 0;
  line-height: 1.5;
}

.tech-list {
  display: flex;
  flex-direction: row;
  gap: 6;
  flex-wrap: wrap;
  list-style-type: none;
  padding-inline-start: 0px;
}

.nav-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
}
